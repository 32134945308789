<template>
  <v-dialog :value="true" width="500" persistent>
    <v-system-bar color="success" window
      >MA-Titel {{ isNew ? "erfassen" : "bearbeiten" }}<v-spacer></v-spacer
      ><v-btn icon @click="close(false)"
        ><v-icon small> mdi-close </v-icon></v-btn
      ></v-system-bar
    >
    <v-card :loading="loading" class="rounded-t-0">
      <v-list subheader>
        <PersonItem :value="item.student"></PersonItem>
        <v-divider></v-divider>
        <v-list-item
          ><v-textarea
            :disabled="loading"
            v-model="item.title"
            label="Titel"
            hint="Längenbegrenzung wegen Zeugniseintrag"
            persistent-hint
            counter="100"
          ></v-textarea
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions
        ><v-spacer></v-spacer>
        <v-btn text @click="close(false)"> abbrechen </v-btn>
        <v-btn color="primary" text @click="saveTitle()"> speichern </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from "vue";
import PersonItem from "common/components/PersonItem.vue";

export default defineComponent({
  props: ["id"],
  components: { PersonItem },
  data() {
    return {
      loading: false,
      item: {},
      saving: false,
    };
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
  },
  methods: {
    close(update) {
      this.$router.push({ name: "Thesis", params: { update: update } });
    },
    async fetchData() {
      if (this.isNew) {
        this.item = { id: 0, title: "" };
      } else {
        this.loading = true;
        this.item = await this.apiGet({
          resource: "thesis/thesis",
          id: this.id,
        });
        this.loading = false;
      }
    },
    async saveTitle() {
      this.saving = true;
      if (this.isNew) {
        await this.apiPost({
          resource: "thesis/thesis",
          data: { id: this.item.id, title: this.item.title },
        });
      } else {
        await this.apiPatch({
          resource: "thesis/thesis",
          id: this.item.id,
          key: "title",
          value: this.item.title,
        });
      }
      this.close(true);
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
